var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('form',{class:_vm.b(),on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('FormGroup',[_c('Field',{attrs:{"placeholder":"ФИО","error-text":_vm.$v.registerModel.name.$dirty && !_vm.$v.registerModel.name.required
            ? 'Поле не заполнено'
            : null,"has-error":_vm.$v.registerModel.name.$error,"is-shake-error":true},on:{"blur":function($event){return _vm.$v.registerModel.name.$touch()}},model:{value:(_vm.registerModel.name),callback:function ($$v) {_vm.$set(_vm.registerModel, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"registerModel.name"}})],1),_c('FormGroup',[_c('Field',{attrs:{"placeholder":"Email","error-text":(_vm.errors && _vm.errors.email ? _vm.errors.email[0] : null) ||
          (_vm.$v.registerModel.email.$dirty && !_vm.$v.registerModel.email.required
            ? 'Поле не заполнено'
            : null) ||
          (_vm.$v.registerModel.email.$dirty && _vm.$v.registerModel.email
            ? `Введите корректный e-mail`
            : null),"has-error":(_vm.errors && !!_vm.errors.email) || _vm.$v.registerModel.email.$error,"is-shake-error":true},on:{"blur":function($event){return _vm.$v.registerModel.email.$touch()}},model:{value:(_vm.registerModel.email),callback:function ($$v) {_vm.$set(_vm.registerModel, "email", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"registerModel.email"}})],1),_c('FormGroup',[_c('Field',{directives:[{name:"mask",rawName:"v-mask",value:('+7(###)###-##-##'),expression:"'+7(###)###-##-##'"}],attrs:{"placeholder":"Телефон","error-text":(_vm.errors && _vm.errors.phone ? _vm.errors.phone[0] : null) ||
          (_vm.$v.registerModel.phone.$dirty && !_vm.$v.registerModel.phone.required
            ? 'Поле не заполнено'
            : null),"has-error":(_vm.errors && !!_vm.errors.phone) || _vm.$v.registerModel.phone.$error,"is-shake-error":true},on:{"blur":function($event){return _vm.$v.registerModel.phone.$touch()}},model:{value:(_vm.registerModel.phone),callback:function ($$v) {_vm.$set(_vm.registerModel, "phone", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"registerModel.phone"}})],1),_c('FormGroup',{class:_vm.b('group')},[_c('BaseCheckbox',{attrs:{"orange":true,"error-text":_vm.$v.registerModel.terms.$dirty && !_vm.$v.registerModel.terms.sameAs
            ? 'Обязательное поле'
            : null},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Я соглашаюсь с условиями "),_c('a',{attrs:{"href":"https://delispace.ru/oferta-uslugi","target":"_blank"}},[_vm._v("договора об использовании платформы")]),_vm._v(" и "),_c('a',{attrs:{"href":"https://delispace.ru/privacy-policy","target":"_blank"}},[_vm._v("политикой обработки персональных данных")])]},proxy:true}]),model:{value:(_vm.registerModel.terms),callback:function ($$v) {_vm.$set(_vm.registerModel, "terms", $$v)},expression:"registerModel.terms"}})],1),_c('BaseButton',{class:_vm.b('submit'),attrs:{"type":"submit","color":'orange',"medium":true}},[_vm._v(" Зарегистрироваться ")]),_c('router-link',{class:_vm.b('link'),attrs:{"to":{ name: 'Login' }}},[_vm._v(" Уже зарегистрированы? ")])],1),_c('Modal',{attrs:{"type":"success","showModal":_vm.showModal,"bgInClass":"animate__fadeIn","bgOutClass":"animate__fadeOut"},on:{"close":function($event){_vm.showModal = false}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Модальное окно")]},proxy:true},{key:"subtitle",fn:function(){return [_vm._v("Регистрация прошла успешно")]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.successMessage)+" ")]},proxy:true},{key:"buttons",fn:function(){return [_c('BaseButton',{attrs:{"large":true},on:{"click":function($event){_vm.showModal = false}}},[_vm._v("Ок")])]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }