
import { Component, Vue } from 'vue-property-decorator'
import AOS from 'aos'
import 'aos/dist/aos.css'

import LandingHeader from './components/LandingHeader.vue'
import LandingInfo from './components/LandingInfo.vue'
import LandingPlatform from './components/LandingPlatform.vue'
import LandingVideo from './components/LandingVideo.vue'
import LandingBlock from './components/LandingBlock.vue'
import LandingRegister from './components/LandingRegister.vue'
import LandingStat from './components/LandingStat.vue'
import LandingFeedback from './components/LandingFeedback.vue'
import LandingFooter from './components/LandingFooter.vue'

@Component({
  name: 'Landing',
  components: {
    LandingHeader,
    LandingInfo,
    LandingPlatform,
    LandingVideo,
    LandingBlock,
    LandingRegister,
    LandingStat,
    LandingFeedback,
    LandingFooter,
  },
  metaInfo: {
    title: 'DЕЛИSPACE - Информация для арендодателей',
  },
})
export default class LandingPage extends Vue {
  public mounted(): void {
    AOS.init()
  }
}
