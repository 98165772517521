var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:_vm.b({ switcher: _vm.switcher, radio: _vm.type === 'radio', small: _vm.small })},[_c('input',_vm._g(_vm._b({class:_vm.b('input', _vm.classesInput),attrs:{"id":_vm.uid,"name":_vm.name,"disabled":_vm.disabled,"type":_vm.type},domProps:{"checked":_vm.isChecked || _vm.$attrs.checked,"value":_vm.value}},'input',_vm.$attrs,false),_vm.listeners)),(_vm.label)?_c('label',{class:_vm.b(
        'label',
        { red: _vm.red, gray: _vm.gray, orange: _vm.orange, white: _vm.white, ['purple-white']: _vm.purpleWhite, disabled: _vm.disabled },
        _vm.classesLabel
      ),attrs:{"for":_vm.uid}},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),(_vm.$slots['label'])?_c('label',{class:_vm.b(
        'label',
        { red: _vm.red, gray: _vm.gray, orange: _vm.orange, white: _vm.white, ['purple-white']: _vm.purpleWhite, disabled: _vm.disabled },
        _vm.classesLabel
      ),attrs:{"for":_vm.uid}},[_vm._t("label")],2):_vm._e(),_c('transition',{attrs:{"name":"slide-left","mode":"out-in"}},[(_vm.errorText)?_c('div',{class:_vm.b('error')},[_vm._v(_vm._s(_vm.errorText))]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }