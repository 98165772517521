
import { Component, Vue } from 'vue-property-decorator'
import LandingTitle from './LandingTitle.vue'
import { Filter, FilterSiteValue, Site } from '@/api/CommonWrapper/types'

interface PlaceTypes {
  code: string
  text: string
}

interface Item {
  id: number
  name: string
  slug: string
  icon: string
  placeTypes: PlaceTypes[]
}

@Component({ name: 'LandingPlatform', components: { LandingTitle } })
export default class LandingPlatform extends Vue {
  list: Item[] = []

  sites: Site[] = []
  filters: Filter[] = []

  defaultList: Item[] = [
    {
      id: 1,
      icon: 'artists.svg',
      name: 'Артисты',
      placeTypes: [
        {
          text: 'Ведущие',
          key: 'artist-1',
        },
        {
          text: 'Аниматоры детских праздников',
          key: 'artist-2',
        },
        {
          text: 'DJ / Диджеи',
          key: 'artist-3',
        },
        {
          text: 'Цирковые артисты',
          key: 'artist-4',
        },
      ],
    },
    {
      id: 2,
      icon: 'contractors.svg',
      name: 'Подрядчики',
      placeTypes: [
        {
          text: 'Кейтеринг',
          key: 'contractor-1',
        },
        {
          text: 'Автопрокат',
          key: 'contractor-2',
        },
        {
          text: 'Техническое обеспечение',
          key: 'contractor-3',
        },
        {
          text: 'Организаторы мероприятий',
          key: 'contractor-4',
        },
        {
          text: 'Фото / видео',
          key: 'contractor-5',
        },
        {
          text: 'Оформление / декор',
          key: 'contractor-6',
        },
      ],
    },
    {
      id: 3,
      icon: 'masters.svg',
      name: 'Мастера',
      placeTypes: [
        {
          text: 'Массажисты',
          key: 'master-1',
        },
        {
          text: 'Косметологи',
          key: 'master-2',
        },
        {
          text: 'Маникюр / педикюр',
          key: 'master-3',
        },
        {
          text: 'Тренеры',
          key: 'master-4',
        },
        {
          text: 'Флористы',
          key: 'master-5',
        },
      ],
    },
    {
      id: 4,
      icon: 'agencies.svg',
      name: 'Агентства',
      placeTypes: [
        {
          text: 'Event агентства',
          key: 'agency-1',
        },
        {
          text: 'Свадебные агентства',
          key: 'agency-2',
        },
        {
          text: 'Тимбилдинг агентства',
          key: 'agency-3',
        },
      ],
    },
  ]

  // private async created() {
  //   let querySites = await this.$api.common.getSitesList()
  //   this.sites = querySites.data.filter(function (item) {
  //     return item.id !== 1
  //   })

  //   let queryFilters = await this.$api.common.getFiltersList()
  //   this.filters = queryFilters.data

  //   const types = this.filters.find((item) => item.type === 'type')

  //   this.list = this.sites.map((site) => {
  //     const placeTypes = (types!.values as FilterSiteValue[])
  //       .filter((type) => {
  //         return type.sites.some((subsite) => subsite.id === site.id)
  //       })
  //       .map((result) => {
  //         return {
  //           code: result.code,
  //           text: result.name,
  //         }
  //       })

  //     return {
  //       id: site.id,
  //       name: site.tab_title,
  //       slug: site.slug,
  //       icon: site.icon,
  //       placeTypes,
  //     }
  //   })
  // }
}
