var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('button',_vm._g(_vm._b({staticClass:"button",class:[
    {
      'button--icon': _vm.icon,
      'button--small': _vm.small,
      'button--medium': _vm.medium,
      'button--large': _vm.large,
      'button--wide': _vm.wide,
      'button--fluid': _vm.fluid,
      'button--outline': _vm.outline && (_vm.color === 'white' || _vm.color === 'black'),
      'button--gray': _vm.color === 'gray',
      'button--green': _vm.color === 'green',
      'button--purple': _vm.color === 'purple',
      'button--red': _vm.color === 'red',
      'button--black': _vm.color === 'black',
      'button--white': _vm.color === 'white' && !_vm.outline,
      'button--orange': _vm.color === 'orange',
    },
    _vm.classes,
  ],attrs:{"type":_vm.type}},'button',{
    ..._vm.$attrs,
    disabled: _vm.isDisabled,
  },false),_vm.$listeners),[(_vm.$slots['icon-left'])?_c('span',{staticClass:"button__icon button__icon--left"},[_vm._t("icon-left")],2):_vm._e(),_vm._t("default"),(_vm.$slots['icon-right'])?_c('span',{staticClass:"button__icon button__icon--right"},[_vm._t("icon-right")],2):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }