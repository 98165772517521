import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { UserModel, UserRequisitesModel } from '@/core/models/UserModel'
import UserService from '@/core/services/UserService'
import router from '@/router'

@Module({
  namespaced: true,
})
export default class UserStore extends VuexModule {
  private user: UserModel = new UserModel()
  private isLoginet = false

  @Mutation
  private setUser(user: UserModel) {
    this.user = user
    this.isLoginet = true
    if (
      user.billing_plan?.current === null &&
      user.billing_plan?.next === null &&
      router.currentRoute.name !== 'Profile'
    ) {
      router.replace('/profile')
    }
  }

  @Mutation
  private setUserAccount(value: number) {
    this.user.account = value.toFixed(2)
  }

  @Action({ commit: 'setUser', rawError: true })
  getUser(): Promise<UserModel> {
    return UserService.getUser()
  }

  @Action
  updateUser(requisites: UserRequisitesModel): Promise<UserRequisitesModel> {
    return UserService.updateUser(requisites)
  }

  get currentUser(): UserModel {
    return this.user
  }

  get isAuth(): boolean {
    return this.isLoginet
  }
}
