
import { Component, Vue } from 'vue-property-decorator'

import BaseButton from '@/components/base/BaseButton.vue'
import BaseIcon from '@/components/base/BaseIcon.vue'
import LandingTitle from './LandingTitle.vue'
import LandingPartnerRequestModalsWrapper from './LandingPartnerRequestModalsWrapper.vue'

@Component({
  name: 'LandingInfo',
  components: {
    LandingTitle,
    LandingPartnerRequestModalsWrapper,
    BaseButton,
    BaseIcon,
  },
})
export default class LandingInfo extends Vue {
  list = [
    {
      id: 1,
      name: 'Зарегистрируйтесь',
      text: 'Заполните максимально подробно анкету исполнителя, подпишитесь на телеграм бот.',
      icon: 'profile',
    },
    {
      id: 2,
      name: 'Получайте клиентов',
      text: 'Звонки и заявки от клиентов DЕЛИ<span class="text-main">SPACE.</span>',
      icon: 'clients',
    },
  ]

  public onRegisterButtonClick(): void {
    this.$router.push('/register')
  }
}
