var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('form',{class:_vm.b(),on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('FormGroup',[_c('Field',{attrs:{"placeholder":"Имя","error-text":_vm.$v.partnerRequestModel.name.$dirty &&
          !_vm.$v.partnerRequestModel.name.required
            ? _vm.requiredErrorText
            : null,"has-error":_vm.$v.partnerRequestModel.name.$error,"is-shake-error":true},on:{"blur":function($event){return _vm.$v.partnerRequestModel.name.$touch()}},model:{value:(_vm.partnerRequestModel.name),callback:function ($$v) {_vm.$set(_vm.partnerRequestModel, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"partnerRequestModel.name"}})],1),_c('FormGroup',[_c('Field',{attrs:{"placeholder":"Email","error-text":(_vm.errors && _vm.errors.email ? _vm.errors.email[0] : null) ||
          (_vm.$v.partnerRequestModel.email.$dirty &&
          !_vm.$v.partnerRequestModel.email.required
            ? _vm.requiredErrorText
            : null) ||
          (_vm.$v.partnerRequestModel.email.$dirty && _vm.$v.partnerRequestModel.email
            ? `Введите корректный e-mail`
            : null),"has-error":(_vm.errors && !!_vm.errors.email) || _vm.$v.partnerRequestModel.email.$error,"is-shake-error":true},on:{"blur":function($event){return _vm.$v.partnerRequestModel.email.$touch()}},model:{value:(_vm.partnerRequestModel.email),callback:function ($$v) {_vm.$set(_vm.partnerRequestModel, "email", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"partnerRequestModel.email"}})],1),_c('FormGroup',[_c('Field',{directives:[{name:"mask",rawName:"v-mask",value:('+7(###)###-##-##'),expression:"'+7(###)###-##-##'"}],attrs:{"placeholder":"Телефон","error-text":(_vm.errors && _vm.errors.phone ? _vm.errors.phone[0] : null) ||
          (_vm.$v.partnerRequestModel.phone.$dirty &&
          !_vm.$v.partnerRequestModel.phone.required
            ? _vm.requiredErrorText
            : null),"has-error":(_vm.errors && !!_vm.errors.phone) || _vm.$v.partnerRequestModel.phone.$error,"is-shake-error":true},on:{"blur":function($event){return _vm.$v.partnerRequestModel.phone.$touch()}},model:{value:(_vm.partnerRequestModel.phone),callback:function ($$v) {_vm.$set(_vm.partnerRequestModel, "phone", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"partnerRequestModel.phone"}})],1),_c('FormGroup',[_c('BaseTextarea',{attrs:{"placeholder":"Сообщение","label":"","class-wrap":_vm.b('field'),"error-text":_vm.errorMessageText,"is-shake-error":true,"has-error":_vm.$v.partnerRequestModel.message.$error,"group":true},on:{"blur":function($event){return _vm.$v.partnerRequestModel.message.$touch()}},model:{value:(_vm.partnerRequestModel.message),callback:function ($$v) {_vm.$set(_vm.partnerRequestModel, "message", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"partnerRequestModel.message"}})],1),_c('BaseButton',{class:_vm.b('submit'),attrs:{"type":"submit","color":"orange","medium":true}},[_vm._v(" Отправить ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }