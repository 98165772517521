
import { Component, Vue } from 'vue-property-decorator'
import LandingTitle from './LandingTitle.vue'
import BaseButton from '@/components/base/BaseButton.vue'
import LandingModal from './LandingModal.vue'
import LandingFeedbackForm from './LandingFeedbackForm.vue'

@Component({
  name: 'LandingFeedback',
  components: {
    LandingTitle,
    BaseButton,
    LandingModal,
    LandingFeedbackForm,
  },
})
export default class LandingFeedback extends Vue {
  isShowModal = false
}
