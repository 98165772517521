import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import AuthStore from '@/store/modules/auth.module'
import UserStore from '@/store/modules/user.module'
import CitiesStore from '@/store/modules/cities.module'
import ImplementerStore from '@/store/modules/implementer.module'
import ServiceTypesStore from '@/store/modules/service-types.module'

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    AuthStore,
    UserStore,
    CitiesStore,
    ImplementerStore,
    ServiceTypesStore,
  },
})
