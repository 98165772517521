
import { Component, Vue } from 'vue-property-decorator'
import LandingTitle from './LandingTitle.vue'
import LandingForm from './LandingForm.vue'

@Component({
  name: 'LandingRegister',
  components: { LandingTitle, LandingForm },
})
export default class LandingRegister extends Vue {}
