
import { Component, Vue } from 'vue-property-decorator'
import { required, email, minLength } from 'vuelidate/lib/validators'
import { ErrorsModel } from '@/core/models/ErrorsModel'
import { FeedbackModel } from '../models/FeedbackModel'

import BaseButton from '@/components/base/BaseButton.vue'
import FormGroup from '@/components/forms/FormGroup.vue'
import Field from '@/components/forms/Field.vue'

import axios from '@/api/axios'

const validations = {
  feedbackModel: {
    name: { required, minLength: minLength(1) },
    email: { required, email },
    phone: { required },
    message: { required },
  },
}

@Component({
  name: 'LandingFeedbackForm',
  components: { FormGroup, Field, BaseButton },
  validations,
})
export default class LandingFeedbackForm extends Vue {
  feedbackModel: FeedbackModel = new FeedbackModel()
  errors: ErrorsModel | null = null
  showModal = false
  successMessage = ''

  async submit(): Promise<any> {
    this.$v.$touch()

    if (this.$v.$invalid) {
      return false
    } else {
      let clone = Object.assign({}, this.feedbackModel)
      let phone = clone.phone

      if (typeof phone === 'string') {
        phone = phone.replace(/\D/g, '')
        clone.phone = +phone
      }

      axios
        .post('/api/common/v1/feedback', {
          name: this.feedbackModel.name,
          email: this.feedbackModel.email,
          phone: phone,
          message: this.feedbackModel.message,
        })
        .then((response) => {
          if (response.status === 200) {
            this.feedbackModel = new FeedbackModel()
            this.$v.$reset()
            this.successMessage = 'Отправлено успешно!'
            this.errors = null
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors
        })
    }
  }
}
