import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import ServiceTypesService from '@/core/services/ServiceTypesService'
import { ServiceTypesModel } from '@/core/models/ServiceTypesModel'

@Module({
  namespaced: true,
})
export default class ServiceTypesStore extends VuexModule {
  private serviceTypes: ServiceTypesModel[] = []

  @Mutation
  private setServiceTypes(serviceTypes: ServiceTypesModel[]) {
    this.serviceTypes = serviceTypes
  }

  @Action({ commit: 'setServiceTypes' })
  getServiceTypes(): Promise<ServiceTypesModel[]> {
    return ServiceTypesService.getServiceTypes()
  }

  get serviceTypesList(): ServiceTypesModel[] {
    return this.serviceTypes
  }
}
