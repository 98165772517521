import axios from '@/api/axios'

class ImplementerProfileService {
  async getImplementerInfo(): Promise<any> {
    try {
      const response = await axios.get('/api/profi/v1/card')
      return response.data.data
    } catch (error: any) {
      return error.response.data
    }
  }

  async addImplementerInfo(info: any): Promise<any> {
    try {
      const response = await axios.post('api/profi/v1/card/add', info)
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('addImplementerInfo', error.message, error.response?.data)
      }
      throw error
    }
  }
}

export default new ImplementerProfileService()
