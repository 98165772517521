import axios from '@/api/axios'

class ServiceTypesService {
  async getServiceTypes(): Promise<any[]> {
    try {
      const response = await axios.get(`/api/profi/v1/categories`)
      return response.data.data
    } catch (error: any) {
      return error.response.data
    }
  }
}

export default new ServiceTypesService()
