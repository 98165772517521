export class UserRequisitesModel {
  bik = ''
  can_edit_requisites = false
  id = 0
  inn = ''
  kpp = ''
  law_form = 'juridical'
  name_full = ''
  ceo_imp = ''
  requisites_updated_at = ''
  rs = null
}

export class UserModel {
  id? = 0
  accepted = false
  invoice = false
  account = ''
  billing_plan_type = ''
  name = ''
  email = ''
  phone = ''
  roles: string[] = []
  requisites: UserRequisitesModel = new UserRequisitesModel()
  billing_plan: {
    current: {
      name: string
      active_from: string
      active_to: string
      price: number
      sum: number
      category: string | null
      max_locations: number
    } | null
    next: {
      name: string
      paid: boolean
      pay_before: string
      price: number
      period: string
      sum: number
      category: string | null
      max_locations: number
    } | null
  } = {
    current: null,
    next: null,
  }
}
