import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import axios from '@/api/axios'
import ImplementerProfileService from '@/modules/ImplementerPage/ImplementerProfileService'

export interface ImplementerDescriptionModel {
  companyName: string
  fullName: string
  city: string
  phone: string
  description: string
  isShowOnSite: boolean
}

export interface ImplementerServiceModel {
  service: string
  priceType: string
  price: number
}

export interface ImplementerWorkExampleModel {
  url: string
}

export interface ImplementerProfileModel {
  id?: number
  ImplementerDescription: ImplementerDescriptionModel
  ImplementerServices: ImplementerServiceModel[]
  ImplementerWorks: ImplementerWorkExampleModel[]
}

@Module({
  namespaced: true,
})
export default class ImplementerProfileStore extends VuexModule {
  private implementerProfileInfo: any = {}
  private implementerProfileModel: ImplementerProfileModel = {
    ImplementerDescription: {
      companyName: '',
      fullName: '',
      city: '',
      phone: '',
      description: '',
      isShowOnSite: false,
    },
    ImplementerServices: [
      {
        service: '',
        priceType: '',
        price: 0,
      },
    ],
    ImplementerWorks: [
      {
        url: '',
      },
    ],
  }

  @Mutation
  private setImplementerProfileInfo(model: any) {
    this.implementerProfileInfo = model
  }

  @Mutation
  private setImplementerProfileModel(profileData: any) {
    this.implementerProfileModel['ImplementerDescription'] = {
      companyName: '',
      fullName: profileData.contact_name,
      city: '',
      phone: profileData.phone,
      description: '',
      isShowOnSite: false,
    }
  }

  @Mutation
  private setImplementerProfileModelByComponent(
    profileData: any,
    currentComponent: string
  ) {
    switch (currentComponent) {
      case 'ImplementerDescription':
        this.implementerProfileModel['ImplementerDescription'] = {
          ...JSON.parse(JSON.stringify(profileData)),
        }
        break
      case 'ImplementerServices':
        // this.implementerProfileModel['ImplementerServices'] = {
        //   ...JSON.parse(JSON.stringify(profileData)),
        // }
        break
      case 'ImplementerWorks':
      // this.implementerProfileModel['ImplementerWorks'] = {
      //   ...JSON.parse(JSON.stringify(profileData)),
      // }
    }
  }

  @Mutation
  private resetImplementerProfileModel() {
    this.implementerProfileModel = {
      ImplementerDescription: {
        companyName: '',
        fullName: '',
        city: '',
        phone: '',
        description: '',
        isShowOnSite: false,
      },
      ImplementerServices: [
        {
          service: '',
          priceType: '',
          price: 0,
        },
      ],
      ImplementerWorks: [
        {
          url: '',
        },
      ],
    }
  }

  @Action
  private async getImplementerProfileModel() {
    const profileInfo = await ImplementerProfileService.getImplementerInfo()
    this.context.commit('setImplementerProfileInfo', profileInfo)
    this.context.commit('setImplementerProfileModel', profileInfo)
  }

  @Action
  private async addProfileInfo() {
    const formData = new FormData()

    const uri =
      this.implementerProfileModel.id === undefined
        ? '/api/b2b/v1/places'
        : '/api/b2b/v1/places/' + this.implementerProfileModel.id

    // await ImplementerProfileService.addImplementerInfo(formData)

    // formData.append('name', this.model.StepOne.name)
    // formData.append('description', this.model.StepOne.description)
    //formData.append('apt', 'test')
    // formData.append(`offers[${i}][from]`, String(conditions[i].from))

    return axios
      .post(uri, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then(() => {
        return {
          status: 'success',
          message: 'Данные сохранены.',
        }
      })
      .catch((response) => {
        console.log(response)
      })
  }

  get profileInfo(): any {
    return this.implementerProfileInfo
  }
}
