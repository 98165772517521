
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class BaseIcon extends Vue {
  @Prop({ required: true })
  iconName!: string

  @Prop()
  width!: number

  @Prop()
  height!: number

  @Prop()
  fill!: string

  @Prop()
  stroke!: string

  get icon(): unknown {
    return require(`@/assets/icons/${this.iconName}.svg`)
  }
}
