var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:_vm.b(null, { error: _vm.isShakeError && _vm.hasError, group: _vm.group, disabled: _vm.disabled })},[(!!_vm.label)?_c('label',{class:_vm.b('label'),attrs:{"for":_vm.id}},[_vm._v(_vm._s(_vm.label))]):_vm._e(),_c('div',{class:_vm.b('block', _vm.classWrap)},[_c('div',{class:_vm.b('wrap')},[(_vm.$slots.prefix)?_c('div',{class:_vm.b('prefix')},[_vm._t("prefix")],2):_vm._e(),_c('input',_vm._g(_vm._b({class:_vm.b('input', {
            suffix: _vm.$slots.suffix,
            prefix: _vm.$slots.prefix,
            error: _vm.hasError,
          }),attrs:{"id":_vm.id,"disabled":_vm.disabled},domProps:{"value":_vm.value}},'input',{ ..._vm.$attrs, type: _vm.initType },false),{
          ..._vm.$listeners,
          beforeinput: _vm.onBeforeInput,
          input: _vm.onInput,
        })),(_vm.$slots.suffix)?_c('div',{class:_vm.b('suffix')},[_vm._t("suffix")],2):_vm._e(),(_vm.type === 'password')?_c('div',{class:_vm.b('eye')},[_c('img',{attrs:{"src":"/img/eye.svg","alt":"Посмотреть"},on:{"mouseenter":function($event){_vm.showPassword = true},"mouseleave":function($event){_vm.showPassword = false}}})]):_vm._e()]),_vm._t("default"),_c('transition',{attrs:{"name":"fade"}},[(_vm.hasError)?_c('div',{class:_vm.b('error')},[_vm._v(" "+_vm._s(_vm.errorText)+" ")]):_vm._e()])],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }