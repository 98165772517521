
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

import BaseButton from '@/components/base/BaseButton.vue'
import LandingModal from './LandingModal.vue'
import LandingPartnerRequestForm from './LandingPartnerRequestForm.vue'

@Component({
  name: 'LandingPartnerRequestModalsWrapper',
  components: {
    LandingModal,
    LandingPartnerRequestForm,
    BaseButton,
  },
})
export default class LandingPartnerRequestModalsWrapper extends Vue {
  @Prop({ required: true })
  isShowForm!: boolean

  @Watch('isShowForm')
  onIsShowFormChanged(isShow: boolean): void {
    if (isShow) {
      this.isShowPartnerRequestModal = true
    } else {
      this.isShowPartnerRequestModal = false
    }
  }

  isShowPartnerRequestModal = false
  isShowSuccessModal = false

  public onPartnerRequestSent(): void {
    this.isShowPartnerRequestModal = false
    this.isShowSuccessModal = true
    this.$emit('close')
  }

  public created(): void {
    this.$root.$on('partnerRequestFormSent', () => {
      if (this.isShowPartnerRequestModal) {
        this.onPartnerRequestSent()
      }
    })
  }
}
