
import { Component, Vue } from 'vue-property-decorator'
import breakpoints from '@/plugins/breakpoints'

import LandingInfo from './LandingInfo.vue'
import BaseButton from '@/components/base/BaseButton.vue'
import BaseIcon from '@/components/base/BaseIcon.vue'

@Component({
  name: 'LandingHeader',
  components: {
    LandingInfo,
    BaseButton,
    BaseIcon,
  },
})
export default class LandingHeader extends Vue {
  breakpoints = breakpoints

  public onLoginButtonClick(): void {
    this.$router.push('/login')
  }
}
