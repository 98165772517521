
import { Component, Vue } from 'vue-property-decorator'
import { required, email, maxLength } from 'vuelidate/lib/validators'
import { ErrorsModel } from '@/core/models/ErrorsModel'
import { FeedbackModel } from '../models/FeedbackModel'

import BaseButton from '@/components/base/BaseButton.vue'
import FormGroup from '@/components/forms/FormGroup.vue'
import Field from '@/components/forms/Field.vue'
import BaseTextarea from '@/components/forms/BaseTextarea.vue'

import axios from '@/api/axios'

const validations = {
  partnerRequestModel: {
    name: { required },
    email: { required, email },
    phone: { required },
    message: { required, maxLength: maxLength(1024) },
  },
}

@Component({
  name: 'LandingPartnerRequestForm',
  components: { FormGroup, Field, BaseButton, BaseTextarea },
  validations,
})
export default class LandingFeedbackForm extends Vue {
  partnerRequestModel: FeedbackModel = new FeedbackModel()
  errors: ErrorsModel | null = null
  showModal = false
  requiredErrorText = 'Поле не заполнено'

  get errorMessageText(): string {
    if (!this.$v.partnerRequestModel.message.required) {
      return this.requiredErrorText
    }
    return this.$v.partnerRequestModel.message.$dirty &&
      !this.$v.partnerRequestModel.message.maxLength
      ? 'Не более 1024 символов'
      : ''
  }

  async submit(): Promise<any> {
    this.$v.$touch()

    if (this.$v.$invalid) {
      return false
    } else {
      let clone = Object.assign({}, this.partnerRequestModel)
      let phone = clone.phone

      if (typeof phone === 'string') {
        phone = phone.replace(/\D/g, '')
        clone.phone = +phone
      }

      axios
        .post(this.getAxiosUrl(), {
          message: this.partnerRequestModel.message,
          email: this.partnerRequestModel.email,
          phone: phone,
          name: this.partnerRequestModel.name,
          lawform: 'person',
          address: 'profi',
        })
        .then((response) => {
          if (response.status === 200) {
            this.partnerRequestModel = new FeedbackModel()
            this.$v.$reset()
            this.errors = null
            this.$root.$emit('partnerRequestFormSent')
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors
        })
    }
  }

  getAxiosUrl(): string {
    const host: string = location.host
    let url = ''
    if (host.includes('dev.') || host.includes('localhost')) {
      url = 'https://dev.backend.delispace.ru/api/common/v1/affiliate'
    } else {
      url = 'https://backend.delispace.ru/api/common/v1/affiliate'
    }
    return url
  }
}
