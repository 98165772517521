import axios from 'axios'
// import jwtDecode, { JwtPayload } from 'jwt-decode'
// import AuthService from '@/modules/AuthPage/AuthService'
import { getItem } from '@/utils/persistanceStorage'
import router from '@/router'

axios.defaults.baseURL = 'https://dev.backend.delispace.ru'

axios.interceptors.request.use(async (config) => {
  const token: string = getItem('token')
  const authorizationToken = token ? `Bearer ${token}` : ''
  config.headers.Authorization = authorizationToken

  // if (token) {
  //   const decoded = jwtDecode<JwtPayload>(token)

  //   if (decoded && decoded.exp && Date.now() > decoded.exp * 1000) {
  //     // await AuthService.refreshToken()
  //     return config
  //   }
  // }

  return config
})

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const value = error.response
    if (value && value.status === 401) {
      localStorage.removeItem('token')
      router.push({ name: 'Login' })
    }

    return Promise.reject(error)
  }
)

export default axios
